// React
import { ReactElement } from 'react';
// Design System
import { Col } from '@utmb/design-system/box/Box';
import { Font } from '@utmb/design-system/text/font';
import { Image } from '@utmb/design-system/image/Image';
import { Link } from '@utmb/design-system/link/link';
import { Spacer } from '@utmb/design-system/spacer/Spacer';
// Types
import { HorizontalAlignEnum, LinkView, SliceMixedView } from '@utmb/types/index';
import { LinkTypeEnum } from '@utmb/types/enum/LinkTypeEnum';
import { Slice } from '@utmb/types/slice-handler';
// Utils
import { parseMarkdown } from '@utmb/utils/markdown';
// Styles
import styles from './mixed.module.scss';
import { ColorTypesEnum } from '@utmb/types/colors';
import { useGlobal } from '@utmb/contexts/GlobalContext';

export const Mixed = (props: Slice<SliceMixedView>): ReactElement => {
    const { id, title, content, picture, links, pictureAlign, allowFullHeight, isDark } = props;

    const {
        global: {
            event: { primaryColorIsDark, secondaryColorIsDark },
        },
    } = useGlobal();

    const classNames = ['container', styles.container, isDark ? styles.is_dark : ''].filter(Boolean).join(' ');

    const getButtonProps = (link: LinkView): any => {
        const className = link.type === LinkTypeEnum.LINK ? styles.link : styles.button;

        return link.type === LinkTypeEnum.BUTTON &&
            ((isDark && !secondaryColorIsDark) || (!isDark && secondaryColorIsDark)) &&
            secondaryColorIsDark !== primaryColorIsDark
            ? {
                  className,
                  href: link.slug,
                  label: link.label,
                  bgcolor: ColorTypesEnum.SECONDARY,
                  isOnSection: true,
              }
            : {
                  className,
                  href: link.slug,
                  label: link.label,
                  isOnSection: true,
              };
    };

    const imageFit = allowFullHeight ? 'contain' : 'cover';

    return (
        <div id={id} className={classNames}>
            <div className="row">
                {pictureAlign === HorizontalAlignEnum.LEFT ? (
                    <div className="col-md-5 offset-md-1">
                        <Image layout="responsive" objectFit={imageFit} width={481} height={543} image={picture} alt={title} maxWidth={600} />
                    </div>
                ) : null}

                <Col className={`${styles.content} col-md-5 offset-md-1`}>
                    {title && (
                        <Font.FuturaHeavy as="h2" mobile="22" desktop="36" className={styles.title}>
                            {title}
                        </Font.FuturaHeavy>
                    )}

                    {content && (
                        <>
                            <Spacer custom="15" />
                            {content && (
                                <Font.FuturaBook mobile="16" desktop="18" className="lh-24 lh-d-28" as="div">
                                    <div
                                        className={`markdown ${primaryColorIsDark ? 'primaryColorIsDark' : ''}`}
                                        dangerouslySetInnerHTML={{ __html: parseMarkdown(content) }}
                                    />
                                </Font.FuturaBook>
                            )}
                            <Spacer custom="15" />
                        </>
                    )}

                    {links && !!links.length && (
                        <>
                            <Spacer custom="25" />
                            {links.map((link: LinkView, index: number) => {
                                const LinkComponent = link.type === LinkTypeEnum.LINK ? Link.Default : Link.Button;

                                return <LinkComponent {...getButtonProps(link)} followClassName={`${link.followClass}-ga`} key={index} />;
                            })}
                        </>
                    )}
                </Col>

                {pictureAlign === HorizontalAlignEnum.RIGHT && (
                    <div className={`${styles.picture_right} col-md-5`}>
                        <Image layout="responsive" objectFit={imageFit} width={481} height={543} image={picture} alt={title} maxWidth={600} />
                    </div>
                )}
            </div>
        </div>
    );
};
